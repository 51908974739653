import React from "react";
import "./global.scss";
import "antd/dist/antd.css";
import { HelmetProvider } from "react-helmet-async";

export const wrapRootElement = ({ element }) => {
	return <HelmetProvider>{element}</HelmetProvider>;
};

export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	// if we're navigating to a hash link just do default behavior
	if (location.hash) {
		return true;
	}
	if (location.pathname === "/") {
		window.scrollTo(0, 0, { behavior: "smooth" });
		return false;
	}

	// go to Top (new page in history) otherwise scroll to the savedPosition
	window.scrollTo(...(getSavedScrollPosition(location) || [0, 0]));
	return false;
};
